@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@font-face {
  font-family: Golos;
  src: url(/static/media/Golos_Text_Bold.58e37dc6.ttf);
  font-weight: Bold;
}

@font-face {
  font-family: Golos;
  src: url(/static/media/Golos_Text_Regular.015c6bdc.ttf);
  font-weight: normal;
}

