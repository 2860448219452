@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@font-face {
  font-family: Golos;
  src: url('./assets/fonts/Golos_Text_Bold.ttf');
  font-weight: Bold;
}

@font-face {
  font-family: Golos;
  src: url('./assets/fonts/Golos_Text_Regular.ttf');
  font-weight: normal;
}
